<template>
  <div class="container" :key="$route.name">
    <div class="top">
      <img src="@/assets/img/kept_logo_2.svg" alt="Kept" class="logo"/>
      <div class="header">
        <TopTypeSelector/>
        <Filter :items="items" :lock-status="report.lock_status" @filter="filter" @divide="divide"/>
        <div class="buttons">
          <div v-if="type !== 105" :class="calculations.length ? '' : 'disabled'">
            <router-link :to="analytics">Аналитика&nbsp;</router-link>
          </div>
          <div :class="calculations.length ? '' : 'disabled'">
            <a @click="downloadReport">Выгрузить данные&nbsp;</a>
          </div>
          <div v-if="!isResult" :class="calculations.length ? '' : 'disabled'">
            <a @click="viewRes(199)">Обзор результата&nbsp;</a>
          </div>
          <div v-if="type === 103">
            <a @click="switchExportModalState">Выгрузка потоков&nbsp;</a>
          </div>
          <template v-else>
            <div :class="matrices.length ? '' : 'disabled'">
              <a @click="viewRes(101)">Расчет резерва&nbsp;</a>
            </div>
            <div :class="matrices.length ? '' : 'disabled'">
              <a @click="viewRes(102)">Расчет рисковой поправки&nbsp;</a>
            </div>
            <div :class="matrices.length ? '' : 'disabled'">
              <a @click="viewRes(103)">Расчет ДП по ОВТ&nbsp;</a>
            </div>
            <div :class="matrices.length ? '' : 'disabled'">
              <a @click="viewRes(104)">Расчет ДП по ООЧП&nbsp;</a>
            </div>
          </template>
        </div>
      </div>

    </div>

    <div class="content">
      <LeftTypeSelector :type="report.type" :items="isResult ? [] : calculations" :can-modify="canModify"
                        :versions="versions" @scroll-to-calc="scrollToCalc" @add-calc="copyCalc"/>
      <div class="block">
        <div v-if="!isReport" class="empty">
          <img src="@/assets/img/info.svg" alt="">
          <span>Для загрузки расчета выберите фильтры "Резервная группа", "Тип расчета", "Отчетная дата"</span>
        </div>
        <div v-else-if="!isResult" v-for="calc in calculations" :key="calc.order" :ref="'report-item-' + calc.id"
             class="scroll">
          <ReportItem :report="report" :calc="calc" @copy-calc="copyCalc" @delete-calc="deleteCalc"
                      @update-calc="updateCalc" @recalculate="recalculate" @download-matrix="downloadMatrix"
                      @reload-matrix-cells="reloadMatrixCells" @set-global="setGlobal"/>
        </div>
        
        <div v-else-if="isResult && matrices.length && report.type === 101" class="scroll">
          <template v-for="i in [0, 3, 4, 5, 6, 7, 8, 9, 10]">
            <div v-if="matrices[i].column_count > 4" class="matrix-block">
              <MatrixHolder :type="199" :status="report.status" :calc="null" :matrix="matrices[i]"
                            :help-string="'*Необходимо выбрать итоговый резерв'" @process="processResult"
                            @reload-matrix-cells="reloadMatrixCells" @download-matrix="downloadMatrix"/>
            </div>
          </template>
          <div class="matrix-block">
            <MatrixHolder :type="199" :status="report.status" :calc="null" :matrix="matrices[2]"
                          :help-string="'*Необходимо выбрать итоговую рисковую поправку'"
                          :is-outdated-report="report.is_outdated" @process="processResult"
                          @reload-matrix-cells="reloadMatrixCells" @recalculate="recalculate"
                          @download-matrix="downloadMatrix"/>
          </div>
        </div>

        <div v-else-if="isResult && matrices.length && report.type === 120" class="scroll">
          <template v-for="i in [0, 1, 2, 3, 4, 5]">
            <div v-if="matrices[i]" class="matrix-block">
              <MatrixHolder :type="198" :status="report.status" :calc="null" :matrix="matrices[i]"
                            :help-string="'*Необходимо выбрать итоговый резерв'" @process="processResult"
                            @reload-matrix-cells="reloadMatrixCells" @download-matrix="downloadMatrix"/>
            </div>
          </template>
        </div>

      </div>
    </div>

  </div>


  <teleport to="body">
    <Modal :show="showModal" @ok="applyTemplate" @cancel="copyVersion([])">
      <template #header>
        <div class="modal-header">Новая версия</div>
      </template>
      <template #body>
        Для выбранной комбинации фильтров была загружена новая версия: <b>{{ newTemplate.version }}</b>
        <br>
        <br>
        Укажите, для каких расчетов применить загруженные значения:
        <ul>
          <li v-for="calc in calculations" class="modal-list-item">
            <input :id="'calc-cb-' + calc.id" :ref="'calc-cb-' + calc.id" type="checkbox">
            <label :for="'calc-cb-' + calc.id">{{ calc.name }}</label>
          </li>
        </ul>
 
      </template>
    </Modal>

  </teleport>

  <teleport to="body">
    <ExportModal :show="showExportModal" 
                 @switchExportModalState="switchExportModalState" 
                 :in-progress="inProgress" 
                 :start="start" 
                 :done="done" />

  </teleport>

  <!-- Блокировка интерфейса при расчетах -->
  <div class="loading" v-show="inProgress">
    <img src="@/assets/img/loading.svg" alt="" srcset="">
  </div>
</template>

<script>
import TopTypeSelector from "@/components/TopTypeSelector";
import Filter from '@/components/Report/Filter'
import LeftTypeSelector from "@/components/LeftTypeSelector";
import ReportItem from "@/components/Report/ReportItem";
import MatrixHolder from "@/components/Report/MatrixHolder";
import Modal from "@/components/Modal.vue";
import ExportModal from "@/components/ExportModal.vue";
import {computed} from "vue";

export default {
  name: 'Report',
  components: {
    TopTypeSelector,
    Filter,
    LeftTypeSelector,
    ReportItem,
    MatrixHolder,
    Modal,
    ExportModal
  },
  data() {
    return {
      polling: null,
      inProgress: false,
      items: [],
      reportId: null,
      report: Object,
      calculations: [],
      matrices: [],
      newTemplate: Object,
      loaded: false,
      state: {},
      divider: this.$divider.value,
      reloadCells: {},
      showModal: false,
      showExportModal: false,
      reportDates: [],
    }
  },
  provide() {
    return {
      children_status: computed(() => this.report.children_status),
      state: computed(() => this.state),
      divider: computed(() => this.divider),
      reloadCells: computed(() => this.reloadCells),
      canModify: computed(() => this.canModify),
      lockStatus: computed(() => this.report.lock_status),
    }
  },
  watch: {
    $route() {
      this.loaded = false
      this.loadFilters()
    }
  },
  computed: {
    type() {
      return Number(this.$route.name.substr(-3))
    },
    isResult() {
      return this.type === 199
    },
    isReport() {
      return (this.$route.params.group && !this.loaded) || this.reportId
    },
    canModify() {
      return this.report.lock_status?.locked === true &&
          ([101, 102, 103].includes(this.report.status) && this.hasPerm('draft')) ||
          ([201, 202, 203].includes(this.report.status) && this.hasPerm('pending_approval'))
    },
    analytics() {
      return this.calculations.length ? `/reports/${this.report.group}/105/${this.report.report_date}` : ''
    },
    versions() {
      switch (this.report.type) {
        case 102:
        case 114:
          return this.report.ru_names
        case 111:
          return this.report.ru_versions
        default:
          return this.report.versions
      }
    }
  },
  mounted() {
    this.loadFilters()
    // this.pollData()
  },
  unmounted() {
    clearInterval(this.polling)
  },
  methods: {
    hasPerm(permission) {
      return window.drfUser.permissions.includes('api.' + permission)
    },
    start() {
      if (this.inProgress) {
        console.warn('Waiting for the response from the server ...')
        return false
      }

      this.inProgress = true
      console.log(this.inProgress)

      return true
    },
    done() {
      this.inProgress = false
      console.log(this.inProgress)

    },
    loadFilters() {
      this.$http
          .get('/reports')
          .then(response => {
            const directResult = response.data.filter(report => report.type === 101 || report.type === 120).map(report => {
              let copy = Object.assign({}, report)
              copy.type =  report.type === 101 ? 199 : 198  
              copy.type_name = report.type === 101 ? 'Обзор результата(прямой бизнес)' : 'Обзор результата(перестрахование)'
              return copy
            })
            this.items = response.data.concat(directResult)
            
            this.reportDates = [...new Set(this.items.map(report => report.report_date))]
            console.log('Report->loadFilters(): done')
          })
          .catch(error => console.error(`Report->loadFilters(): ERROR: ${error}`))
    },
    filter(id) {
      if (id) {
        this.reportId = id
        this.loadReport(this.loaded)
      } else {
        this.reportId = null
        this.report = Object
        this.calculations = []
        this.matrices = []
        this.newTemplate = Object
      }
    },
    divide(value) {
      this.divider = value
    },
    loadReport(reload = false, calcId = null, calcNumber = null) {
      this.$http
          .get('/reports/' + this.reportId)
          .then(response => {
            this.report = response.data
            this.calculations = this.report.calculation_set
            this.matrices = this.report.matrix_set
            this.newTemplate = this.type !== 199 ? this.report.new_template : null
            this.showModal = this.canModify && !!this.newTemplate

            this.loaded = true
            if (reload) {
              this.reloadCells = {'ids': []}
            }
            if (calcId == null && calcNumber != null && this.calculations) {
              calcId = this.calculations[calcNumber].id
            }

            console.log(`Report->loadReport(${this.report.id}): done`)
          })
          .catch(error => console.error(`Report->loadReport(): ERROR: ${error}`))
          .finally(() => this.scrollToCalc(calcId))
    },
    copyCalc(data) {
      if (this.start()) {
        this.$http
            .post(`/calculations/${data.id}/copy-to/${data.report_id}`, data)
            .then(response => {
              const id = response.data.id
              console.log(`Report->copyCalculation(${data.id} -> ${id}): done`)
              this.loadReport(false, id)
            })
            .catch(error => console.error('Report->copyCalculation(): ERROR: ' + error))
            .finally(() => this.done())
      }
    },
    deleteCalc(id) {
      if (this.start()) {
        this.$http
            .delete(`/calculations/${id}`)
            .then(() => {
              console.log(`Report->deleteCalculation(${id}): done`)
              this.loadReport(false, null, 0)
            })
            .catch(error => console.error(`Report->deleteCalculation(): ERROR: ${error}`))
            .finally(() => this.done())
      }
    },
    scrollToCalc(id) {
      if (id) {
        let el = this.$refs['report-item-' + id]
        if (el && el instanceof Array) {
          el = el[0]
        }
        if (el) {
          el.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
        }
      }
    },
    viewRes(type) {
      this.$router.push(`/reports/${this.report.group}/${type}/${this.report.report_date}`)
    },
    updateCalc(data) {
      if (this.start()) {
        this.$http
            .put(`/calculations/${data.id}`, data)
            .then(response => {
              console.log(`Report->updateCalculation(${data.id}): done`)
              this.loadReport()

              const ids = response.data.ids
              if (ids.length) {
                this.reloadCells = {'ids': ids}
              }
            })
            .catch(error => {
              console.error(`Report->updateCalculation(): ERROR: ${error}`)
              this.loadReport()
            })
            .finally(() => this.done())
      }
    },
    recalculate(id) {
      if (this.start()) {
        this.$http
            .post(id ? `/calculations/${id}/recalculate` : `/reports/${this.report.id}/recalculate`)
            .then(response => {
              console.log(id ?
                  `Report->recalculateCalculation(${id}): done` :
                  `Report->recalculate(${this.report.id}): done`)
              this.loadReport()

              const ids = response.data.ids
              if (ids.length) {
                this.reloadCells = {'ids': ids}
              }
            })
            .catch(error => console.error(`Report->recalculate(): ERROR: ${error}`))
            .finally(() => this.done())
      }
    },
    reloadMatrixCells(ids) {
      this.reloadCells = {'ids': ids}
      this.loadReport()
    },
    applyTemplate() {
      let ids = []
      this.calculations.forEach((calc, _) => {
        let el = this.$refs['calc-cb-' + calc.id]
        if (el && el instanceof Array) {
          el = el[0]
        }
        if (el && el.checked) {
          ids.push(calc.id)
        }
      })
      this.copyVersion(ids)
    },
    copyVersion(ids) {
      if (this.start()) {
        this.showModal = false
        this.$http
            .post(`/calculations/${this.newTemplate.id}/copy-version`, {'ids': ids})
            .then(response => {
              console.log(`Report->copyVersion(${this.newTemplate.id}): done`)
              this.loadReport()

              ids = response.data.ids
              if (ids.length) {
                this.reloadCells = {'ids': ids}
              }
            })
            .catch(error => console.error(`Report->copyVersion(): ERROR: ${error}`))
            .finally(() => this.done())
      }
    },
    downloadMatrix(data) {
      if (this.start()) {
        this.$http({url: '/export/matrices', method: 'POST', data: data, responseType: 'blob'})
            .then(response => {
              const blob = new Blob([response.data], {type: response.data.type});
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob)
              link.download = "data.xlsx";
              link.click();

              console.log('Report->downloadMatrix(): done')
            })
            .catch(error => console.error(`Report->downloadMatrix(): ERROR: ${error}`))
            .finally(() => this.done())
      }
    },
    downloadReport() {
      this.downloadMatrix({'model': 'Report', 'is_result': this.isResult, 'ids': [this.report.id]})
    },
    processResult(data) {
      if (this.start()) {
        data.ids = [this.report.id]
        this.$http
            .post('/reports/process', data)
            .then(response => {
              console.log(`Report->processResult('${data.action}'): ${response.data.info}`)
              this.loadReport()
            })
            .catch(error => console.log(`Report->processResult('${data.action}'): ERROR: ${error}`))
            .finally(() => this.done())
      }
    },
    setGlobal(id) {
      if (this.start()) {
        this.$http
            .post(`/calculations/${id}/set-global`)
            .then(response => {
              console.log(`Calculation->setGlobal(${response.data.id})`)
              this.loadReport()
            })
            .catch(error => console.log(`Calculation->setGlobal('${id}'): ERROR: ${error}`))
            .finally(() => this.done())
      }
    },
    pollData() {
      if (!this.polling) {
        this.polling = setInterval(() => {
          this.loadReport()
        }, 10000)
      }
    },
    switchExportModalState() {
      this.showExportModal = !this.showExportModal;
    },
  }
}
</script>

<style scoped>
.top {
  display: flex;
  align-items: center;
  background-color: #feffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  padding-top: 1rem;
  width: 100%;
  height: 13rem;
  position: fixed;
  z-index: 1000;
  margin: 0;
  top: 0;
}

.logo {
  margin-left: 2rem;
}

.header {
  position: fixed;
  left: 16rem;
  top: 0;
  width: calc(100% - 17rem);
}

.buttons {
  display: flex;
  padding-bottom: 1rem;
  align-items: start;
}

.buttons div {
  padding: 0.5rem;
  border-bottom: 2px solid #3ebaff;
}

.buttons a {
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: #3ebaff;
  white-space: nowrap;
  font-size: 0.95rem;
}

.buttons a:after {
  content: url('@/assets/img/indicator_active.svg');
}

.buttons div.disabled {
  border-bottom: 1px solid #6b6f77;
}

.buttons div.disabled a {
  color: #6b6f77;
}

.buttons div.disabled a:after {
  content: url('@/assets/img/indicator_no_active.svg');
}

.content {
  display: flex;
  background-color: #feffff;
  margin-top: 13rem;
}

.block {
  padding: 1rem;
  background-color: #efeeee;
  margin-left: 15rem;
  width: 100%;
}

.scroll {
  scroll-margin-top: calc(13rem + 1rem);
  scroll-margin-left: calc(15rem + 1rem);
}

.empty {
  display: flex;
  align-items: center;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  background-color: #fdfdfd;
  padding: 2rem;
  margin: 1rem;
}

.empty > img {
  transform: rotate(180deg);
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.matrix-block {
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #e0e0e0;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
}

/* Стиль для блокировки интерфейса */
.loading{
  position: fixed;
  left: 0;
  top: 0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading img{
  width: 3rem;
}
</style>
