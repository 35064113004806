<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">Заголовок</slot>
        </div>
        <div class="modal-body">
          <slot name="body">Сообщение</slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button class="modal-default-button" @click="$emit('ok')">OK</button>
            <button class="modal-default-button" @click="$emit('cancel')">Отменить</button>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  emits: ['ok', 'cancel'],
  props: {
    show: Boolean,
  },
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  min-width: 400px;
  width: max-content;
  margin: auto;
  padding: 1.5rem 2rem;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header {
}

.modal-body {
  margin: 1.5rem 0;
  max-width: 900px;
  max-height: 55vh;
  overflow-y: auto;
}

.modal-footer{
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-default-button {
  float: right;
  width: 7rem;
  padding: 0.1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
