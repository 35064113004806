<template>
  <div id="container">
    <Top :show-user="true"/>
    <div class="body">
      <div class="title">
        Загрузка данных
      </div>
      <div class="block">
        <table>
          <tbody>
          <tr>
            <td>Мультизагрузка</td>
            <td>
              <input v-model="multiupload" type="checkbox" class="version">
            </td>
          </tr>
          <tr v-if="!multiupload">
            <td>Резервная группа</td>
            <td>
              <select ref="group" @change="group = $event.target.value">
                <option value="" :selected="!group"></option>
                <option v-for="group in groups" :key="group.id" :value='group.id' :selected="group === group.id">{{ group.name }}</option>
              </select>
            </td>
          </tr>
          
          <tr v-if="!multiupload">
            <td>Тип расчета</td>
            <td>
              <select ref="type" @change="type = $event.target.value">
                <option value="" :selected="!type"></option>
                <option value="101" :selected="type === '101'">Резерв убытков</option>
                <option value="103" :selected="type === '103'">ДП по ОВТ</option>
                <option value="117" :selected="type === '117'">ДП по ОВТ (мес)</option>
                <option value="128" :selected="type === '128'">ДП по ОВТ (исходящее)</option>
                <option value="130" :selected="type === '130'">ДП по ОВТ (мес исходящее)</option>
                <option value="104" :selected="type === '104'">ДП по ООЧП</option>
                <option value="116" :selected="type === '116'">ДП по ООЧП (мес)</option>
                <option value="127" :selected="type === '127'">ДП по ООЧП (исходящее)</option>
                <option value="129" :selected="type === '129'">ДП по ООЧП (мес исходящее)</option>
                <option value="114" :selected="type === '114'">РП по ООЧП</option>
                <option value="106" :selected="type === '106'">РРУУ прям.</option>
                <option value="107" :selected="type === '107'">РРУУ проч.</option>
                <option value="108" :selected="type === '108'">РРУУ судебн.</option>
                <option value="109" :selected="type === '109'">Ожидаемые ГОТС</option>
                <option value="110" :selected="type === '110'">Ожидаемые регрессы</option>
                <option value="112" :selected="type === '112'">Резерв нулевых требований</option>
                <option value="113" :selected="type === '113'">Ожидаемые разницы от РСА</option>
                <option value="120" :selected="type === '120'">Перестрахование</option>
                <option value="118" :selected="type === '118'">Прочие потоки</option>
                <option value="131" :selected="type === '131'">Прочие потоки исходящее</option>
                <option value="119" :selected="type === '119'">Прочие потоки (мес)</option>
              </select>
            </td>
          </tr>

          <tr v-if="!multiupload">
            <td>Версия</td>
            <td>
              <input type="text" ref="version" :value="version" @change="version = $event.target.value" class="version">
            </td>
          </tr>
          <tr>
            <td v-if="!multiupload">Файл для загрузки</td>
            <td v-else="multiupload">Файлы для загрузки</td>
            <td>
              <input type="file" ref="mainFile" @change="mainFileUpload" class="upload" accept=".xlsx, .xls" :multiple="multiupload">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="buttons">
        <a :class="'pax-button' + (canUpload ? '' : ' disabled')" @click="submitFiles">
          Загрузить данные
        </a>
        <a :class="'pax-button' + (mainFile ? '' : ' disabled')" @click="clear">Очистить выбор</a>
      </div>
      <div class="error">
        {{ error }}
      </div>
    </div>
  </div>

  <teleport to="body">
    <Modal :show="resultModalActive" @ok="switchResultModalState" @cancel="switchResultModalState">
      <template #header>
        <div class="modal-header">{{ multiuploadStarted ? 'Идет загрузка файлов...' :  'Загрузка завершена!'}}</div>
      </template>
      <template #body v-if="resultErrors.length && !multiuploadStarted">
        Обнаружены следующие ошибки в файлах:
        <br>
        <br>
        <ul>
          <li v-for="error in resultErrors" class="modal-list-item">
            {{ error }}
          </li>
        </ul>
      </template>
      <template #body v-else-if="!multiuploadStarted">
        Все файлы успешно загружены!
      </template>
      <template #body v-else>
        Это может занять время
      </template>
    </Modal>
  </teleport>
</template>

<script>
import Top from "@/components/Top";
import Modal from "@/components/Modal.vue";

export default {
  name: 'Upload',
  components: {
    Top,
    Modal
  },
  data() {
    return {
      groups: [],
      inProgress: false,
      group: '',
      type: '',
      version: '',
      mainFile: null,
      error: null,
      multiupload: false,
      resultModalActive: false,
      resultErrors: [],
      multiuploadStarted: false,
    }
  },
  computed: {
    canUpload(){
      if(this.multiupload){
        return this.mainFile
      }else{
        return this.group && this.type && this.version && this.mainFile
      }
    }
  },
  mounted() {
    this.loadData()
  },
  watch:{
    multiupload(){
      this.clear()
    }
  },
  methods: {
    switchResultModalState() {
      this.resultModalActive = !this.resultModalActive
    },
    loadData() {
      this.$http
        .get(`/settings`)
        .then(response => {
          this.groups = response.data.groups
          console.log('Settings->loaded(): done')
        })
        .catch(error => console.error('Settings->loaded(): ERROR: ' + error))
    },
    mainFileUpload() {
      if(!this.multiupload){
        this.mainFile = this.$refs.mainFile.files[0]
        this.error = null
      }else{
        this.mainFile = this.$refs.mainFile.files
        this.error = null
      }
    },
    clear() {
      this.group = ''
      this.type = ''
      this.version = ''
      this.mainFile = null
      this.$refs.mainFile.value = null
      this.error = null
    },
    start() {
      if (this.inProgress) {
        console.warn('Waiting for the response from the server ...')
        return false
      }

      this.inProgress = true
      return true
    },
    done() {
      this.inProgress = false
    },
    submitFiles() {
      if(this.multiupload){
        if (!this.mainFile) {
          return
        }
      }else{
        if (!this.group || !this.type || !this.version || !this.mainFile) {
          return
        }
      }

      this.error = null

      const formData = new FormData()
      formData.append('group', this.group)
      formData.append('type', this.type)
      formData.append('version', this.version)

      if (this.multiupload){
        this.multiuploadStarted = true;
        this.resultModalActive = true;
        for(const file in Object.keys(this.mainFile)){
          formData.append('files[]', this.mainFile[file])
        }
      }else{
        formData.append('main_file', this.mainFile)
      }


      if (this.start()) {
        this.$http
            .post(this.multiupload ? '/multiupload/' : '/upload/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((response) => {
              this.multiuploadStarted = false;
              console.log('Upload(): done')
              
              if(this.multiupload){
                this.resultErrors = response.data.errors
              }else{
                this.$router.push('/dashboard')
              }

            })
            .catch(error => {
              this.resultModalActive = false;
              this.multiuploadStarted = false;
              const data = error.response.data
              this.error = 'Ошибка загрузки файла: ' + (data?.error || error.message)
              console.error('Upload(): ERROR: ', error)
            })
            .finally(() => this.done())
      }
    }
  }
}
</script>

<style scoped>
.body {
  margin-top: 5.2rem;
}

.title {
  font-size: 1.3rem;
  color: #3ebaff;
  padding: 1rem 1rem 0.5rem 2rem;
}

.block {
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #e0e0e0;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 0 2rem 0.5rem 0;
}

td:first-child {
  width: 14rem;
}

select {
  width: 13rem;
}

.buttons {
  margin-left: 2rem;
}

.version {
  width: 13rem;
  padding: 0.1rem;
  font-size: 0.9rem;
}

.upload {
  font-size: 0.9rem;
  width: 100%;
}

input::file-selector-button {
  font-size: 0.9rem;
  color: white;
  background-color: #1dafed;
  border: none;
  border-radius: 3px;
  transition: 0.3s;
  cursor: pointer;
  width: 13rem;
  margin-right: 1rem;
  padding: 0.3rem;
}

input::file-selector-button:hover {
  background-color: #139cd7;
}

.pax-button {
  width: 13rem;
  margin: 0 1rem 1rem 0;
}

.error {
  font-size: 1.2rem;
  margin: 2rem 0 0 2rem;
  color: darkred;
  white-space: pre-line;
}
</style>
